<template>
    <div class="login-container">
        <h2>Logowanie</h2>

        <div v-if="mfaSMS == false" class="p-field">
            <label for="username">Login</label>
            <InputText id="username" v-model="username" placeholder="Wprowadź login" />
        </div>

        <div class="p-field">
            <label v-if="mfaSMS == false" for="password">Hasło</label>
            <label v-if="mfaSMS == true" for="password">Kod autoryzacyjny</label>
            <Password id="password" v-model="password" :feedback="false" toggleMask :placeholder="getPlaceHolder" />
        </div>

        <div v-if="mfaSMS == false" class="p-field-checkbox">
            <Checkbox inputId="rememberMe" v-model="rememberMe" :binary="true" />
            <label for="rememberMe">Zapamiętaj mnie na tym komputerze</label>
        </div>

        <div v-if="rememberMe && 1==0" class="p-field">
            <label for="password">Nazwa komputera</label>
            <InputText id="computerName" v-model="computerName" feedback="false" toggleMask placeholder="Wprowadź nazwę komputera" />
        </div>

        <Message v-for="msg of messages" :key="msg.id" severity="error" :life="3000">{{ msg.content }}</Message>
        <Button label="Zaloguj się" class="p-button-success" @click="onSubmit" />
    </div>
</template>

<script>
    import axios from 'axios';
    import Password from 'primevue/password';

    export default {
        components: { Password },
        data() {
            return {
                username: '',
                password: '',
                rememberMe: false,
                computerName: "",
                mfaSMS: false,
                messages: []
            };
        },
        computed: {
            getPlaceHolder() {
                return (this.mfaSMS) ? 'Wprowadź kod' : 'Wprowadź hasło';
            }
        },
        mounted: function () {
            let deviceKey = localStorage.getItem("DeviceKey");
            if (deviceKey != undefined) {
                this.rememberMe = deviceKey != 'undefined';
            }
        },
        methods: {
            async onSubmit() {
                try {
                    let deviceKey = localStorage.getItem("DeviceKey");
                    if (deviceKey == undefined) {
                        deviceKey = ''
                    }
                    if (!this.mfaSMS) {
                        const response = await axios.post('../api/users/authenticate', {
                            username: this.username,
                            password: this.password,
                            rememberMe: this.rememberMe,
                            computerName: this.computerName,
                            deviceKey: deviceKey
                        });
                        if (response.data.twoFactorAuthentication == "SMS") {
                            this.password = "";
                            this.mfaSMS = true;
                        }
                        else if (response.data.token.length > 20)
                        {
                            localStorage.setItem("DeviceKey", response.data.twoFactorAuthentication);
                            sessionStorage.setItem("User", JSON.stringify(response.data));
                            this.$emit('update-value', 543);
                            console.log('Zalogowano pomyślnie:', response.data);
                        }
                    }
                    else {
                        const response = await axios.post('../api/users/TwoFactorAuthenticationConfirm', {
                            username: this.username,
                            password: this.password,
                            rememberMe: this.rememberMe,
                            computerName: this.computerName,
                            deviceKey: deviceKey
                        });
                        sessionStorage.setItem("User", JSON.stringify(response.data));
                        if (response.data.twoFactorAuthentication == "SMS") {
                            this.mfaSMS = true;
                        }
                        else {
                            if (response.data.twoFactorAuthentication != undefined) {
                                localStorage.setItem("DeviceKey", response.data.twoFactorAuthentication);
                            }
                            this.$emit('update-value', 543);
                            console.log('Zalogowano pomyślnie:', response.data);
                        }
                    }       
                    
                } catch (error) {
                    this.messages.push({ id: 2, content: (this.mfaSMS) ? 'Nieprawidłowy kod' : 'Nieprawidłowy login lub hasło' });
                    console.error('Błąd logowania:', error);
                }
            }
        }
    };
</script>

<style scoped>
    .login-container {
        max-width: 400px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    h2 {
        text-align: center;
    }

    .p-field {
        margin-bottom: 16px;
    }

    .p-field-checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }
</style>