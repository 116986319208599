<template>
    <header>
        <div class="header">
            <img src="./assets/logo.png" @click="$router.push('/')" />
            <Button v-if="getCurrentUser != undefined" class="menu-button" label="" icon="pi pi-bars" @click="sidebarVisible = true" />
        </div>
    </header>   
    <router-view v-if="getCurrentUser != undefined"></router-view>
    <LoginForm v-if="getCurrentUser == undefined" @update-value="updateParentValue" />

    <Sidebar v-if="getCurrentUser != undefined" v-model:visible="sidebarVisible" position="right" header="Menu">
        <ProgressSpinner v-if="isLoading" />
        <Menu v-else :model="menuItemsPrv" />
    </Sidebar>

    <Toast />
    <ScrollTop />

</template>

<script>    
    //import { userService } from "./services/user.service"
    import LoginForm from "./components/LoginForm.vue";

    export default {
        name: 'App',
        components: { LoginForm },
        data() {
            return {
                forceRecomputeCounter: 0,
                isLoading: false,
                sidebarVisible: false,
                menuItemsPrv:[],
                menuItems: [
                    {
                        items: [
                            {
                                label: 'Home',
                                icon: 'pi pi-home',
                                command: () => {
                                    this.$router.push('/')
                                },
                                permission: ''
                            },
                            {
                                label: 'Inwentaryzacja kodów',
                                icon: 'pi pi-search',
                                command: () => {
                                    this.$router.push('/CodeForm')
                                },
                                permission: ''
                            },
                            {
                                label: 'Invoice emails',
                                icon: 'pi pi-search',
                                command: () => {
                                    this.$router.push('/upload-invoice-email-admin')
                                },
                                permission: 'InvEmailFileUploadAdmin'
                            },  
                            {
                                label: 'Wyloguj',
                                icon: 'pi pi-search',
                                command: () => {
                                    sessionStorage.removeItem("User");
                                    this.forceRecomputeCounter++;
                                },
                                permission: ''
                            },  
                        ],
                    },
                ]
            }
        },
        computed: {           
            getCurrentUser() {    
                this.forceRecomputeCounter;
                let user = JSON.parse(sessionStorage.getItem("User"));                
                return user;
            }
        },
        methods: {           
            updateParentValue(newValue) {
                this.forceRecomputeCounter = newValue;
                //this.menuItemsPrv = this.menuItems[0].items.filter(item => item.permission === '' || user.userPermissions.some(permission => permission.Name === item.permission));
                this.menuItemsPrv = this.menuItems[0].items.filter(item => item.permission === '');
            },
            scroll(toTop) {
                window.scrollTo({
                    top: toTop? 0 : document.body.scrollHeight,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        },
        watch: {
            "$route": function (oldRoute, newRoute) {
                this.sidebarVisible = false;
                this.forceRecomputeCounter++;
                if (!(newRoute.fullPath === '/' && oldRoute.fullPath !== newRoute.fullPath)) sessionStorage.removeItem('formData');
            }
        },
        mounted: function () {
            this.menuItemsPrv = this.menuItems[0].items.filter(item => item.permission === '');
            this.forceRecomputeCounter++;
        },
    }
</script>

<style>
    body {
        margin: 0;
        padding: 0;
    }

    * {
        box-sizing: border-box;
    }

    header {
        max-width: 100vw;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em 2em;
        box-shadow: 0 0 10px var(--primary-color);
        width: 100%;
    }

        header > img {
            box-shadow: 0 0 10px white, 0 0 50px white inset;
            animation: breathing infinite 10s;
        }

    @keyframes breathing {
        0% {
            box-shadow: 0 0 10px white, 0 0 50px white inset;
        }

        50% {
            box-shadow: 0 0 5px white, 0 0 10px white inset;
        }

        100% {
            box-shadow: 0 0 10px white, 0 0 50px white inset;
        }
    }

    .menu-button {
        height: 3em;
        width: 3em;
    }

    .p-checkbox-box {
        border-color: var(--primary-color);
    }
</style>
