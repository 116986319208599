import { createWebHistory, createRouter } from "vue-router";

import CompanyForm from "../components/CompanyForm.vue";
import CodeForm from "../components/CodeForm.vue";

const routes = [    
    {
        path: '/CompanyForm',
        component: CompanyForm
    },
    {
        path: '/CodeForm',
        component: CodeForm
    }
]; 

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
