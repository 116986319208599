import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import PrimeVue from 'primevue/config';

import Menu from "primevue/menu";
import Toast from "primevue/toast";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Column from 'primevue/column';
import Sidebar from "primevue/sidebar";
import Calendar from "primevue/calendar";
import Textarea from 'primevue/textarea';
import Checkbox from "primevue/checkbox";
import Dropdown from 'primevue/dropdown';
import ScrollTop from 'primevue/scrolltop';
import DataTable from 'primevue/datatable';
import InputText from "primevue/inputtext";
import FloatLabel from "primevue/floatlabel";
import RadioButton from "primevue/radiobutton";
import InputNumber from "primevue/inputnumber";
import InputSwitch from 'primevue/inputswitch';
import ToastService from 'primevue/toastservice';
import ProgressSpinner from "primevue/progressspinner";
import ConfirmationService from 'primevue/confirmationservice';
import Message from 'primevue/message';
import ConfirmDialog from 'primevue/confirmdialog';
import OverlayPanel from 'primevue/overlaypanel';

import 'primevue/resources/themes/aura-dark-green/theme.css';
import 'primeicons/primeicons.css';

const primeVueLocale = {};
primeVueLocale['PL'] = {
    startsWith: 'Zaczyna si�',
    contains: 'Zawiera',
    notContains: 'Nie zawiera',
    endsWith: 'Ko�czy si�',
    equals: 'R�wne',
    notEquals: 'Nie r�wne',
    noFilter: 'Brak filtra',
    lt: 'Mniejsze ni�',
    lte: 'Mniejsze lub r�wne',
    gt: 'Wi�ksze ni�',
    gte: 'Wi�ksze lub r�wne',
    dateIs: 'Data jest',
    dateIsNot: 'Data nie jest',
    dateBefore: 'Data przed',
    dateAfter: 'Data po',
    clear: 'Wyczy��',
    apply: 'Zastosuj',
    matchAll: 'Logiczne "i"',
    matchAny: 'Logiczne "lub"',
    addRule: 'Dodaj regu��',
    removeRule: 'Usu� regu��',
    accept: 'Tak',
    reject: 'Nie',
    choose: 'Wybierz',
    upload: 'Wy�lij',
    cancel: 'Anuluj',
    dayNames: ["Niedziela", "Poniedzia�ek", "Wtorek", "�roda", "Czwartek", "Pi�tek", "Sobota"],
    dayNamesShort: ["Nie", "Pon", "Wto", "�ro", "Czw", "Pt", "Sob"],
    dayNamesMin: ["Nd", "Pn", "Wt", "�r", "Cz", "Pt", "So"],
    monthNames: [
        "Stycze�", "Luty", "Marzec", "Kwiecie�", "Maj", "Czerwiec", "Lipiec", "Sierpie�", "Wrzesie�", "Pa�dziernik",
        "Listopad", "Grudzie�"
    ],
    monthNamesShort: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Pa�", "Lis", "Gru"],
    today: 'Dzisiaj',
    weekHeader: 'Tydz',
    firstDayOfWeek: 1,
    dateFormat: 'dd-mm-yy',
    weak: 'Cienki',
    medium: '�redni',
    strong: 'Gruby',
    passwordPrompt: 'Podaj has�o',
    emptyFilterMessage: 'Brak wynik�w',
    emptyMessage: 'Brak opcji'
};


const app = createApp(App);

app.use(router);
app.use(PrimeVue, { locale: primeVueLocale['PL'] });
app.use(ToastService);
app.use(ConfirmationService);

app.component('Menu', Menu);
app.component('Toast', Toast);
app.component('Dialog', Dialog);
app.component('Button', Button);
app.component('Message', Message);
app.component('Column', Column);
app.component('Sidebar', Sidebar);
app.component('Calendar', Calendar);
app.component('Textarea', Textarea);
app.component('Checkbox', Checkbox);
app.component('Dropdown', Dropdown);
app.component('ScrollTop', ScrollTop);
app.component('DataTable', DataTable);
app.component('InputText', InputText);
app.component('FloatLabel', FloatLabel);
app.component('InputNumber', InputNumber);
app.component('RadioButton', RadioButton);
app.component('InputSwitch', InputSwitch);
app.component('ProgressSpinner', ProgressSpinner);
app.component('ConfirmDialog', ConfirmDialog);
app.component('OverlayPanel', OverlayPanel);

app.config.globalProperties.$filters = {
    confirmAction(message, header, acceptCallback, rejectCallback) {
        app.config.globalProperties.$confirm.require({
            message: message,
            header: header,
            icon: 'pi pi-exclamation-triangle',
            accept: acceptCallback,
            reject: rejectCallback,
        });
    }
};

app.mount('#app');
